<template>
  <div class="register" v-loading="pageLoading">
    <div class="cont">
      <img src="@/assets/images/register.png" alt="">
      <div class="p-20">
        <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="200px"
      >
        <el-form-item label="公司名称：" prop="basic_com_name">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_com_name"
              autocomplete="off"
              placeholder="请输入公司名称"
            ></el-input>
            <div class="bref flex-cell">须与营业执照一致的公司名称</div>
          </div>
        </el-form-item>
        <el-form-item label="统一社会信用代码：" prop="basic_uscc_id_card">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_uscc_id_card"
              autocomplete="off"
              placeholder="请输入统一社会信用代码"
            ></el-input>
            <div class="bref flex-cell">国家标准委发布的统一社会信用代码</div>
          </div>
        </el-form-item>
        <el-form-item label="归属地区：" prop="expand_data_area">
          <div class="flex-row">
            <div class="flex-cell">
              <v-distpicker class="flex-row" :province-disabled="true" province="甘肃省"
                        city="兰州市"
                        area="城关区"
                        @selected="distSelected"></v-distpicker>
            </div>
            <div class="bref flex-cell">
              公司归属地域，请注意正确填写，否则平台地域审核员接收不到入会申请
            </div>
          </div>
        </el-form-item>
        <el-form-item label="企业类别：" prop="basicComType">
          <div class="flex-row">
            <el-cascader
                ref="basicComType"
                class="flex-cell"
                placeholder="请选择企业类别"
                v-model="formData.basicComType"
                :options="comTypeOptions"
                @change="doChangeType"></el-cascader>
            <div class="bref flex-cell">须与营业执照一致的企业类别</div>
          </div>
        </el-form-item>
        <el-form-item label="服务分类：" prop="cate_ids">
          <div class="flex-row">
            <el-select class="flex-cell" v-model="formData.cate_ids" multiple placeholder="请选择">
              <el-option
                v-for="item in cateArr"
                style="color: #000"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <div class="bref flex-cell">服务分类</div>
          </div>
        </el-form-item>
        <el-form-item label="经营范围：" prop="basic_serv_area">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              type="textarea"
              :rows="3"
              v-model="formData.basic_serv_area"
              autocomplete="off"
              placeholder="请输入经营范围"
            ></el-input>
            <div class="bref flex-cell">须与营业执照一致的经营范围</div>
          </div>
        </el-form-item>
        <el-form-item label="注册金额：" prop="basic_reg_amount">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_reg_amount"
              type="number"
              autocomplete="off"
              placeholder="请输入注册金额"
            ></el-input>
            <div class="bref flex-cell">单位为：万元/人民币</div>
          </div>
        </el-form-item>
        <el-form-item label="注册日期：" prop="basic_reg_time">
          <div class="flex-row">
            <el-date-picker
                class="flex-cell"
                v-model="formData.basic_reg_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择注册日期">
                </el-date-picker>
            <div class="bref flex-cell">企业注册日期，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="注册地址：" prop="basic_reg_address">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_reg_address"
              autocomplete="off"
              placeholder="请输入注册地址"
            ></el-input>
            <div class="bref flex-cell">企业注册地址，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="经营起始日期：" prop="basic_sell_start_date">
          <div class="flex-row">
            <el-date-picker
                class="flex-cell"
                v-model="formData.basic_sell_start_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择经营起始日期">
                </el-date-picker>
            <div class="bref flex-cell">经营起始日期，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="经营截止日期：" prop="basic_sell_end_date">
          <div class="flex-row">
            <el-date-picker
                class="flex-cell"
                v-model="formData.basic_sell_end_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择经营截止日期">
                </el-date-picker>
            <div class="bref flex-cell">经营截止日期，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="法人姓名：" prop="basic_ceo_name">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_ceo_name"
              autocomplete="off"
              placeholder="请输入法人姓名"
            ></el-input>
            <div class="bref flex-cell">法人姓名，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="法人身份证号：" prop="basic_ceo_id_card">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_ceo_id_card"
              autocomplete="off"
              placeholder="请输入法人身份证号"
            ></el-input>
            <div class="bref flex-cell">法人身份证号，须与营业执照保持一致</div>
          </div>
        </el-form-item>
        <el-form-item label="法人联系方式：" prop="basic_ceo_phone">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.basic_ceo_phone"
              autocomplete="off"
              placeholder="请输入法人联系方式"
            ></el-input>
            <div class="bref flex-cell">法人联系方式，注意手机号码准确性</div>
          </div>
        </el-form-item>
        <el-form-item label="企业联系人：" prop="contacts_name">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.contacts_name"
              autocomplete="off"
              placeholder="请输入企业联系人"
            ></el-input>
            <div class="bref flex-cell">企业联系人</div>
          </div>
        </el-form-item>
        <el-form-item label="企业联系电话：" prop="contacts_mobile">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.contacts_mobile"
              autocomplete="off"
              maxlength="11"
              placeholder="请输入企业联系电话"
            ></el-input>
            <div class="bref flex-cell">企业联系电话</div>
          </div>
        </el-form-item>
        <el-form-item label="成立时间：" prop="establish_date">
          <div class="flex-row">
            <el-date-picker
                class="flex-cell"
                v-model="formData.establish_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择成立时间">
                </el-date-picker>
            <div class="bref flex-cell">成立时间</div>
          </div>
        </el-form-item>
        <el-form-item label="单位地址：" prop="company_addr">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.company_addr"
              autocomplete="off"
              placeholder="请输入单位地址"
            ></el-input>
            <div class="bref flex-cell">单位地址</div>
          </div>
        </el-form-item>
        <el-form-item label="企业简介：" prop="company_bref">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              type="textarea"
              :rows="3"
              v-model="formData.company_bref"
              autocomplete="off"
              placeholder="请输入企业简介"
            ></el-input>
            <div class="bref flex-cell">企业简介</div>
          </div>
        </el-form-item>
        <el-form-item label="企业链接：" prop="official_link">
          <div class="flex-row">
            <el-input
              class="flex-cell"
              v-model="formData.official_link"
              autocomplete="off"
              placeholder="请输入企业链接"
            ></el-input>
            <div class="bref flex-cell">企业链接</div>
          </div>
        </el-form-item>
        <el-form-item label="企业logo：" prop="basic_logo" ref="headimgUpload">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file-img v-model="formData.basic_logo" @watchFileList="watchFileList" @deleteFile="deleteFile" field="basic_logo" />
                <!-- <div class="el-form-item__error" v-if="isUploadLogo">请上传单位logo</div> -->
            </div>
            <div class="bref flex-cell">企业logo</div>
          </div>
        </el-form-item>
        <el-form-item label="公众号二维码：" prop="name">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file-img @watchFileList="watchFileList" @deleteFile="deleteFile" field="official_account_img" />
            </div>
            <div class="bref flex-cell">公众号二维码</div>
          </div>
        </el-form-item>
        <el-form-item label="小程序二维码：" prop="name">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file-img @watchFileList="watchFileList" @deleteFile="deleteFile" field="mini_program_img" />
            </div>
            <div class="bref flex-cell">小程序二维码</div>
          </div>
        </el-form-item>
        <el-form-item label="安卓下载二维码：" prop="name">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file-img @watchFileList="watchFileList" @deleteFile="deleteFile" field="android_img" />
            </div>
            <div class="bref flex-cell">安卓下载二维码</div>
          </div>
        </el-form-item>
        <el-form-item label="IOS下载二维码：" prop="name">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file-img @watchFileList="watchFileList" @deleteFile="deleteFile" field="ios_img" />
            </div>
            <div class="bref flex-cell">IOS下载二维码</div>
          </div>
        </el-form-item>
        <el-form-item label="上传附件：" prop="name">
          <div class="flex-row">
            <div class="flex-cell">
                <upload-file field="basic_qualifications" @watchFiles="watchFiles" />
            </div>
            <div class="bref flex-cell">
              仅支持照片格式资质附件上传，多个请重复操作，如需删除请点击已上传内容右侧删除图标
            </div>
          </div>
        </el-form-item>
        <el-form-item label="验证码：" prop="captcha">
          <div class="flex-row txt-center">
            <div class="flex-cell flex-row">
                <el-input
                class="flex-cell"
                v-model="formData.captcha"
                autocomplete="off"
                ></el-input>
                <div class="flex-cell" @click="refreshCode()">
                <div class="codeImg">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
                </div>
            </div>
            <div class="bref flex-cell">验证码为必输项</div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer txt-center">
        <el-button @click="resetForm('formData')">关 闭</el-button>
        <el-button type="primary" @click="doSubmit('formData')"
          >提 交</el-button
        >
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import sIdentify from "@/components/sIdentify.vue";
import VDistpicker from 'v-distpicker'
const comTypeOptions = require('@/components/companyType.json')
export default {
  name: "Home",
  components: { sIdentify,VDistpicker, },
  
  data() {
    var checkCode = (rule, value, callback) => {
      if (value != this.identifyCode) {
        callback(new Error("验证码输入有误，请重新输入"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      if (!regExp.test(value)) {
        callback(new Error("手机号码格式有误，请重新输入。"));
      } else {
        callback();
      }
    };
    var checkIdCard = (rule, value, callback) => {
      var regExp = /(^\d{15}$)|(^\d{17}([0-9Xx])$)/;
      if (!regExp.test(value)) {
        callback(new Error("身份证号码格式有误，请重新输入。"));
      } else {
        callback();
      }
    };
    const validateLogo = (rule, value, callback) => {
      if (!this.formData.basic_logo) {
        callback(new Error('请上传logo'))
      } else {
        callback()
      }
    }
    return {
      pageLoading: false,
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ],
      formData: {
        expand_data_area: '甘肃省,兰州市,城关区',
        basic_logo: null
      },
      rules: {
        basic_com_name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        basic_uscc_id_card: [{ required: true, message: "请输入统一社会信用代码", trigger: "blur" }],
        basicComType: [{ required: true, message: "请选择企业类型", trigger: "change" }],
        basic_serv_area: [{ required: true, message: "请输入经营范围", trigger: "blur" }],
        cate_ids: [{ required: true, message: "请选择服务分类", trigger: "blur" }],
        basic_reg_amount: [{ required: true, message: "请输入注册金额", trigger: "blur" }],
        basic_reg_time: [{ required: true, message: "请选择注册日期", trigger: "blur" }],
        basic_reg_address: [{ required: true, message: "请输入注册地址", trigger: "blur" }],
        basic_sell_start_date: [{ required: true, message: "请选择经营起始日期", trigger: "blur" }],
        basic_sell_end_date: [{ required: true, message: "请选择经营截止日期", trigger: "blur" }],
        basic_ceo_name: [{ required: true, message: "请输入法人姓名", trigger: "blur" }],
        basic_ceo_id_card: [{ required: true, message: "请输入法人身份证号", trigger: "blur" },{ validator: checkIdCard, trigger: "blur" }],
        basic_ceo_phone: [{ required: true, message: "请输入法人联系方式", trigger: "blur" },{ validator: checkPhone, trigger: "blur" }],
        contacts_name: [{ required: true, message: "请输入企业联系人", trigger: "blur" }],
        contacts_mobile: [{ required: true, message: "请输入企业联系电话", trigger: "blur" }],
        establish_date: [{ required: true, message: "请选择成立时间", trigger: "blur" }],
        company_addr: [{ required: true, message: "请输入单位地址", trigger: "blur" }],
        company_bref: [{ required: true, message: "请输入企业简介", trigger: "blur" }],
        // official_link: [{ required: true, message: "请输入企业链接", trigger: "blur" }],
        basic_logo: [{ required: true, validator: validateLogo, trigger: 'blur' }],
        captcha: [{ required: true, message: "请输入验证码！", trigger: "blur" },
          { validator: checkCode, trigger: "blur" }],
          
      },
      comTypeOptions: comTypeOptions,
      options: [],
      basicComType: [],
      cateArr: [],
      isUploadLogo: false
    };
  },
  mounted() {
    this.refreshCode();
    l_loadData(this)
  },
  methods: {
    // 区域选择
    distSelected (data) {
      const info = {
        codes: data.province.code + ',' + data.city.code + ',' + data.area.code,
        names: data.province.value + ',' + data.city.value + ',' + data.area.value
      }
      this.formData.expand_data_area = info.names ? info.names : '甘肃省,兰州市,城关区'
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      console.log("当前验证码:", this.identifyCode);
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      console.log("data, len:", data, len);
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    doChangeType(e){
      this.formData.basic_com_type = this.$refs["basicComType"].getCheckedNodes()[0].pathLabels.join(',')
    },
    // 获取上传的图片
    watchFileList(field, val){
      console.log(field)
      if(field == 'basic_logo'){
        this.$refs['headimgUpload'].clearValidate()
      }
      this.formData[field] = val
    },
    // 删除图片
    deleteFile(field){
      this.formData[field] = ''
    },
    watchFiles(files){
      console.log(files)
      // basic_qualifications
      this.formData.basic_qualifications = files
    },
    // 提交
    doSubmit(formName) {
      var that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.$confirm('确认提交', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            c_doSubmit(that);
          }).catch(() => {});
        }
      });
    },
    // 取消重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.isPopShow = false;
    },
  },
};

// 加载数据
function l_loadData(pg){
  pg.pageLoading = true;
  requestGet("api/company/cates", {})
    .then((res) => {
      pg.cateArr = res.data || []
      pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}

function c_doSubmit(pg) {
  var values = {
    android_img: pg.formData.android_img,
    basic_ceo_id_card: pg.formData.basic_ceo_id_card,
    basic_ceo_name: pg.formData.basic_ceo_name,
    basic_ceo_phone: pg.formData.basic_ceo_phone,
    basic_com_name: pg.formData.basic_com_name,
    basic_com_type: pg.formData.basic_com_type,
    basic_logo: pg.formData.basic_logo,
    basic_qualifications: pg.formData.basic_qualifications,
    basic_reg_address: pg.formData.basic_reg_address,
    basic_reg_amount: pg.formData.basic_reg_amount,
    basic_reg_time: pg.formData.basic_reg_time,
    basic_sell_end_date: pg.formData.basic_sell_end_date,
    basic_sell_start_date: pg.formData.basic_sell_start_date,
    basic_serv_area: pg.formData.basic_serv_area,
    basic_uscc_id_card: pg.formData.basic_uscc_id_card,
    cate_ids: pg.formData.cate_ids.join(','),
    company_addr: pg.formData.company_addr,
    company_bref: pg.formData.company_bref,
    contacts_mobile: pg.formData.contacts_mobile,
    contacts_name: pg.formData.contacts_name,
    establish_date: pg.formData.establish_date,
    expand_data_area: pg.formData.expand_data_area,
    ios_img: pg.formData.ios_img,
    mini_program_img: pg.formData.mini_program_img,
    official_account_img: pg.formData.official_account_img,
  }
  requestPost("api/company/reg", values)
    .then((res) => {
      if(res.code == '200'){
        pg.$message.success(res.msg);
        pg.$router.go(-1);
      }else{
        pg.$message.error(res.msg);
      }
    })
    .catch((error) => {
      pg.$message.error(error);
    });
}
</script>
<style lang="scss" scoped>
.register {
  width: 1200px;
  margin: 0 auto;
  .cont {
    background: #fff;
    .bref{
        margin-left: 10px;
        font-size: 12px;
        color: rgba(0,0,0,.45);
    }
  }
  .codeImg {
    width: 200px;
    height: 40px;
    margin-left: 20px;
  }
}
::v-deep .distpicker-address-wrapper{
  label{
    -webkit-box-flex: 1;
    flex: 1;
    select{
      width: 100%;
      height: 35px;
    line-height: 35px;
    font-size: 14px;
    }
  }
  label:nth-child(2){
    margin: 0 10px;
  }
  
}
::v-deep .el-input__inner{
    height: 35px;
    line-height: 35px;
  }
  ::v-deep .el-form-item{
    margin-bottom: 18px!important;
  }
  ::v-deep .el-input__inner{
    border: 1px solid #DCDFE6!important;
  }
 
  
</style>
